import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import Cookies from "js-cookie";

export const getUsers = createAsyncThunk(
  "Admin/getUsers",
  async (_, thunkAPI) => {
    const { rejectWithValue } = thunkAPI;
    try {
      const data = await axios
        .get(`${process.env.REACT_APP_BACKEND_API}api/admin/users`, {
          headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
            Authorization: `Bearer ${Cookies.get("Willgo_Super_Token")}`,
          },
        })
        .then((res) => res.data);
      return data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

// ============================
export const getAdminDetails = createAsyncThunk(
  "Admin/getAdminDetails",
  async (_, thunkAPI) => {
    const { rejectWithValue } = thunkAPI;
    try {
      const result = await axios.get(
        `${process.env.REACT_APP_BACKEND_API}api/admin/admins/me/profile`,
        {
          headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
            Authorization: `Bearer ${Cookies.get("Willgo_Super_Token")}`,
          },
        }
      );
      return result.data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);
export const getAdmin = createAsyncThunk(
  "Admin/getAdmin",
  async (data, thunkAPI) => {
    const { rejectWithValue } = thunkAPI;
    try {
      const result = await axios.get(
        `${process.env.REACT_APP_BACKEND_API}api/admin/admins`,
        {
          headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
            Authorization: `Bearer ${Cookies.get("Willgo_Super_Token")}`,
          },
          params: data && data,
        }
      );
      return result.data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

export const addAdmin = createAsyncThunk(
  "Admin/addAdmin",
  async (data, thunkAPI) => {
    const { rejectWithValue } = thunkAPI;
    try {
      const result = await axios
        .post(`${process.env.REACT_APP_BACKEND_API}api/admin/admins`, data, {
          headers: {
            "Content-Type": "multipart/form-data",
            Accept: "application/json",
            Authorization: `Bearer ${Cookies.get("Willgo_Super_Token")}`,
          },
        })
        .then((res) => res.data);

      return result;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

export const EditAdmin = createAsyncThunk(
  "Admin/EditAdmin",
  async (data, thunkAPI) => {
    const { rejectWithValue } = thunkAPI;
    try {
      const result = await axios
        .put(
          `${process.env.REACT_APP_BACKEND_API}api/admin/admins/${data.id}`,
          data,
          {
            headers: {
              "Content-Type": "application/json",
              Accept: "application/json",
              Authorization: `Bearer ${Cookies.get("Willgo_Super_Token")}`,
            },
          }
        )
        .then((res) => res.data);

      return result;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

export const delAdmin = createAsyncThunk(
  "Admin/delAdmin",
  async (id, thunkAPI) => {
    const { rejectWithValue } = thunkAPI;
    try {
      const response = await axios.delete(
        `${process.env.REACT_APP_BACKEND_API}api/admin/admins/${id}`,
        {
          headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
            Authorization: `Bearer ${Cookies.get("Willgo_Super_Token")}`,
          },
        }
      );
      return response.data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

export const getAdminId = createAsyncThunk(
  "Admin/getAdminId",
  async (id, thunkAPI) => {
    const { rejectWithValue } = thunkAPI;
    try {
      const data = await axios
        .get(`${process.env.REACT_APP_BACKEND_API}api/admin/admins/${id}`, {
          headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
            Authorization: `Bearer ${Cookies.get("Willgo_Super_Token")}`,
          },
        })
        .then((res) => res.data);
      return data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

// ============================

export const getRoles = createAsyncThunk(
  "Admin/getRoles",
  async (_, thunkAPI) => {
    const { rejectWithValue } = thunkAPI;
    try {
      const data = await axios
        .get(`${process.env.REACT_APP_BACKEND_API}api/admin/roles`, {
          headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
            Authorization: `Bearer ${Cookies.get("Willgo_Super_Token")}`,
          },
          params: {
            guard_name: "admin",
          },
        })
        .then((res) => res.data);
      return data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);
export const getPermissions = createAsyncThunk(
  "Admin/getPermissions",
  async (_, thunkAPI) => {
    const { rejectWithValue } = thunkAPI;
    try {
      const data = await axios
        .get(`${process.env.REACT_APP_BACKEND_API}api/admin/permissions`, {
          headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
            Authorization: `Bearer ${Cookies.get("Willgo_Super_Token")}`,
          },
          params: {
            guard_name: "admin",
          },
        })
        .then((res) => res.data);
      return data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

export const UpdateProfile = createAsyncThunk(
  "Admin/UpdateProfile",
  async (data, thunkAPI) => {
    const { rejectWithValue } = thunkAPI;
    try {
      const result = await axios
        .post(
          `${process.env.REACT_APP_BACKEND_API}api/admin/admins/me/profile/update`,
          data,
          {
            headers: {
              "Content-Type": "multipart/form-data",
              Accept: "application/json",
              Authorization: `Bearer ${Cookies.get("Willgo_Super_Token")}`,
            },
          }
        )
        .then((res) => res.data);

      return result;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);
const AdminSlice = createSlice({
  name: "Admin",
  initialState: {
    isAdminLoading: false,
    error: null,
    UsersArr: null,
    getAdminArray: null,
    AdminIDArray: null,
    RolesArr: null,
    AdminDetailsArr: null,
    PermissionsArr: null,
  },

  extraReducers: {
    [getUsers.pending]: (state, action) => {
      state.isAdminLoading = true;
    },
    [getUsers.fulfilled]: (state, action) => {
      state.UsersArr = action.payload.data;
      state.isAdminLoading = false;
    },
    [getUsers.rejected]: (state, action) => {
      state.isAdminLoading = false;
    },
    //getAdminDetails
    [getAdminDetails.pending]: (state, action) => {
      state.isAdminLoading = true;
    },
    [getAdminDetails.fulfilled]: (state, action) => {
      state.isAdminLoading = false;
      state.AdminDetailsArr = action.payload.data;
    },
    [getAdminDetails.rejected]: (state, action) => {
      state.isAdminLoading = false;
    },

    [addAdmin.pending]: (state, action) => {
      state.isAdminLoading = true;
    },
    [addAdmin.fulfilled]: (state, action) => {
      state.isAdminLoading = false;
    },
    [addAdmin.rejected]: (state, action) => {
      state.isAdminLoading = false;
    },
    //
    //
    [delAdmin.pending]: (state, action) => {
      state.isAdminLoading = true;
    },
    [delAdmin.fulfilled]: (state, action) => {
      state.isAdminLoading = false;
    },
    [delAdmin.rejected]: (state, action) => {
      state.isAdminLoading = false;
    },
    //
    //
    [EditAdmin.pending]: (state, action) => {
      state.isAdminLoading = true;
    },
    [EditAdmin.fulfilled]: (state, action) => {
      state.isAdminLoading = false;
    },
    [EditAdmin.rejected]: (state, action) => {
      state.isAdminLoading = false;
    },
    //
    [getAdminId.pending]: (state, action) => {
      state.isAdminLoading = true;
    },
    [getAdminId.fulfilled]: (state, action) => {
      state.AdminIDArray = action.payload.data;
      state.isAdminLoading = false;
    },
    [getAdminId.rejected]: (state, action) => {
      state.isAdminLoading = false;
    },
    //
    [getAdmin.pending]: (state, action) => {
      state.isAdminLoading = true;
    },
    [getAdmin.fulfilled]: (state, action) => {
      state.getAdminArray = action.payload.data.data;
      state.isAdminLoading = false;
    },
    [getAdmin.rejected]: (state, action) => {
      state.isAdminLoading = false;
    },
    // getRoles
    [getRoles.pending]: (state, action) => {
      state.isAdminLoading = true;
    },
    [getRoles.fulfilled]: (state, action) => {
      state.RolesArr = action.payload.data;
      state.isAdminLoading = false;
    },
    [getRoles.rejected]: (state, action) => {
      state.isAdminLoading = false;
    },
    // getPermissions
    [getPermissions.pending]: (state, action) => {
      state.isAdminLoading = true;
    },
    [getPermissions.fulfilled]: (state, action) => {
      state.PermissionsArr = action.payload.data;
      state.isAdminLoading = false;
    },
    [getPermissions.rejected]: (state, action) => {
      state.isAdminLoading = false;
    },
    // UpdateProfile
    [UpdateProfile.pending]: (state, action) => {
      state.isAdminLoading = true;
    },
    [UpdateProfile.fulfilled]: (state, action) => {
      state.isAdminLoading = false;
    },
    [UpdateProfile.rejected]: (state, action) => {
      state.isAdminLoading = false;
    },
  },
});

export default AdminSlice.reducer;
