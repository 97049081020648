import React, { useEffect, useRef, useState } from "react";
import { Toast } from "primereact/toast";
import style from "../styles/Forms.module.css";
import { MdOutlineDashboardCustomize } from "react-icons/md";
import { NavLink, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { addSetting, getSetting, updateSetting } from "../store/HomeSlice";
import { InputTextarea } from "primereact/inputtextarea";
import { FaPlus } from "react-icons/fa";
import { useFormik } from "formik";
import { Dialog } from "primereact/dialog";
import { InputText } from "primereact/inputtext";
import { classNames } from "primereact/utils";

const Settings = () => {
  const { lang } = useParams();
  const { t } = useTranslation();
  const toast = useRef(null);
  const dispatch = useDispatch();
  const { SettingsArray } = useSelector((state) => state.HomeSlice);
  const { AdminDetailsArr } = useSelector((state) => state.AdminSlice);

  // State to manage input values
  const [settingsValues, setSettingsValues] = useState({});

  useEffect(() => {
    // Fetch settings on component mount
    if (!SettingsArray) {
      dispatch(getSetting());
    }
  }, [dispatch, SettingsArray]);

  // Initialize local state with settings data
  useEffect(() => {
    if (SettingsArray) {
      const initialValues = SettingsArray.reduce((acc, setting) => {
        acc[setting.key] = setting.value;
        return acc;
      }, {});
      setSettingsValues(initialValues);
    }
  }, [SettingsArray]);

  const handleInputChange = (key, value) => {
    setSettingsValues((prevValues) => ({
      ...prevValues,
      [key]: value,
    }));
  };

  const handleSave = (key, id) => {
    const savedValue = settingsValues[key];
    const data = {
      id,
      key,
      value: savedValue,
    };
    dispatch(updateSetting(data))
      .unwrap()
      .then(() => {
        Success();
        dispatch(getSetting());
      })
      .catch((err) => {
        Error(err[0]);
      });
  };

  const Success = () => {
    toast.current.show({
      severity: "success",
      summary: t("tabel.success"),

      life: 3000,
    });
  };
  const Error = (mess) => {
    toast.current.show({
      severity: "error",
      summary: `${mess}`,
      life: 3000,
    });
  };
  const [visible, setVisible] = useState(false);

  const formik = useFormik({
    initialValues: {
      key: "",
      value: "",
    },
    validate: (data) => {
      let errors = {};
      if (data.key.trim() === "" || !data.key) {
        errors.key = `${t("settings.key")} ${t("tabel.requierd")}`;
      }
      if (data.value.trim() === "" || !data.value) {
        errors.value = `${t("settings.value")} ${t("tabel.requierd")}`;
      }

      return errors;
    },
    onSubmit: (values) => {
      if (values) {
        dispatch(addSetting(values))
          .unwrap()
          .then((res) => {
            dispatch(getSetting());
            Success();
            setVisible(false);
            formik.resetForm();
          })
          .catch((err) => {
            err?.errors.map((ele) => {
              return Error(ele);
            });
          });
      }
    },
  });

  const isFormFieldInvalid = (name) =>
    !!(formik.touched[name] && formik.errors[name]);
  const getFormErrorMessage = (name) => {
    return isFormFieldInvalid(name) ? (
      <small className="p-error">{formik.errors[name]}</small>
    ) : (
      <small className="p-error">&nbsp;</small>
    );
  };
  return (
    <div className="container-fluid">
      <Toast ref={toast} />
      <div className="bredcreamp">
        <NavLink to={`/${lang}`}>
          <MdOutlineDashboardCustomize /> {t("navbar.Dashboard")}{" "}
        </NavLink>
        / {t("settings.key")}
      </div>
      <div className="cardd">
        <div className="title-page flex justify-content-between align-items-center mb-3">
          <h3>{t("settings.key")}</h3>
          {AdminDetailsArr?.permissions?.add_settings && (
            <button
              className="add-btn"
              onClick={() => {
                setVisible(true);
              }}
            >
              <FaPlus /> {t("settings.add")}
            </button>
          )}
        </div>
        <div className="card">
          {SettingsArray?.map((ele) => (
            <div key={ele.id}>
              <div className={style.field}>
                <label htmlFor={ele.key}>{ele.key}</label>
                <InputTextarea
                  id={ele.key}
                  name={ele.key}
                  value={settingsValues[ele.key] || ""}
                  onChange={(e) => handleInputChange(ele.key, e.target.value)}
                  rows={10}
                />
              </div>
              {AdminDetailsArr?.permissions?.add_settings && (
                <button
                  type="button"
                  className="add-btn ml-auto mt-4"
                  onClick={() => handleSave(ele.key, ele.id)}
                >
                  {t("settings.save")}
                </button>
              )}
            </div>
          ))}
        </div>
      </div>

      <Dialog
        header={t("settings.add")}
        visible={visible}
        style={{ maxWidth: "70vw", minWidth: "50vw" }}
        onHide={() => {
          if (!visible) return;
          setVisible(false);
          formik.resetForm();
        }}
      >
        <form
          autoComplete="false"
          onSubmit={formik.handleSubmit}
          className={style.form}
        >
          <div className={style.field}>
            <label htmlFor="key">{t("settings.key")}</label>
            <InputText
              maxLength={255}
              id="key"
              name="key"
              value={formik.values.key}
              className={classNames({
                "p-invalid": isFormFieldInvalid("key"),
              })}
              onChange={formik.handleChange}
            />
            {getFormErrorMessage("key")}
          </div>
          <div className={style.field}>
            <label htmlFor="value"> {t("settings.value")}</label>
            <InputText
              maxLength={255}
              id="value"
              name="value"
              value={formik.values.value}
              className={classNames({
                "p-invalid": isFormFieldInvalid("value"),
              })}
              onChange={formik.handleChange}
            />
            {getFormErrorMessage("value")}
          </div>

          <button type="submit" className="add-btn ml-auto mt-4">
            {t("settings.save")}
          </button>
        </form>
      </Dialog>
    </div>
  );
};

export default Settings;
