import { Route, Routes } from "react-router-dom";
import Loader from "./components/layouts/loading/loading";
import React, { Suspense, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getAdminDetails } from "./store/AdminSlice";
import Settings from "./pages/Settings";
import EditeContarct from "./pages/Contracts/EditeContarct";

// import Profile from "./pages/profile/Profile";
const Login = React.lazy(() => import("./pages/auth/login"));
const Protected = React.lazy(() => import("./pages/auth/Protected"));
const DriverContact = React.lazy(() => import("./pages/contact/DriverContact"));
const Contact = React.lazy(() => import("./pages/contact/Contact"));
const Admin = React.lazy(() => import("./pages/admin/Admin"));
const Profile = React.lazy(() => import("./pages/profile/Profile"));
// const Roles = React.lazy(() => import("./pages/admin/Roles"));
// const AddRoles = React.lazy(() => import("./pages/admin/AddRoles"));
const Orders = React.lazy(() => import("./pages/orders/Orders"));
const AddOrder = React.lazy(() => import("./pages/orders/AddOrder"));
const AddAdmin = React.lazy(() => import("./pages/admin/AddAdmin"));
const AdminEdit = React.lazy(() => import("./pages/admin/AdminEdit"));
const ViewOrder = React.lazy(() => import("./pages/orders/ViewOrder"));
const Users = React.lazy(() => import("./pages/Users/Users"));
const Edit_Users = React.lazy(() => import("./pages/Users/Edit_Users"));
const AddUsers = React.lazy(() => import("./pages/Users/AddUsers"));
const AddRestaurantsWrap = React.lazy(() =>
  import("./pages/resturants/AddRestaurantsWrap")
);
const Resturants = React.lazy(() => import("./pages/resturants/Resturants"));
const RestaurantView = React.lazy(() =>
  import("./pages/resturants/RestaurantView")
);
const EditeDriverWrap = React.lazy(() =>
  import("./pages/Drivers/EditeDriverWrap")
);
const AddDriverWrap = React.lazy(() => import("./pages/Drivers/AddDriverWrap"));
const Drivers = React.lazy(() => import("./pages/Drivers/Drivers"));
const Contracts = React.lazy(() => import("./pages/Contracts/Contracts"));
const FollowContracts = React.lazy(() =>
  import("./pages/Contracts/FollowContracts")
);
const AddContract = React.lazy(() => import("./pages/Contracts/AddContract"));

const Class = React.lazy(() => import("./pages/ProductManagement/Class"));
const Category = React.lazy(() => import("./pages/ProductManagement/Category"));
const AddCategory = React.lazy(() =>
  import("./pages/ProductManagement/AddCategory")
);
const AddClass = React.lazy(() => import("./pages/ProductManagement/AddClass"));
const EditClass = React.lazy(() =>
  import("./pages/ProductManagement/EditClass")
);
const Country = React.lazy(() => import("./pages/Country/Country"));
const AddCountry = React.lazy(() => import("./pages/Country/AddCountry"));
const City = React.lazy(() => import("./pages/City/City"));
const AddCity = React.lazy(() => import("./pages/City/AddCity"));
const EditeZoneWrap = React.lazy(() => import("./pages/Zones/EditeZoneWrap"));
const Zones = React.lazy(() => import("./pages/Zones/Zones"));
const AddZoneWrap = React.lazy(() => import("./pages/Zones/AddZoneWrap"));
const EditCity = React.lazy(() => import("./pages/City/Edit_City"));

const Plans = React.lazy(() => import("./pages/Plans/Plans"));
const PlansView = React.lazy(() => import("./pages/Plans/PlansView"));
const PlansEdit = React.lazy(() => import("./pages/Plans/PlansEdit"));
const PlansAdd = React.lazy(() => import("./pages/Plans/PlansAdd"));
const RestaurantEditWrap = React.lazy(() =>
  import("./pages/resturants/RestaurantEditWrap")
);
const EditCategory = React.lazy(() =>
  import("./pages/ProductManagement/EditCategory")
);
const DriversView = React.lazy(() => import("./pages/Drivers/DriversView"));
const Edit_Country = React.lazy(() => import("./pages/Country/Edit_Country"));
const Slider = React.lazy(() => import("./pages/Slider/Slider"));
const Edit_Slider = React.lazy(() => import("./pages/Slider/Edit_Slider"));
const AddSlider = React.lazy(() => import("./pages/Slider/AddSlider"));
const FinancialManagementOrders = React.lazy(() =>
  import("./pages/FinancialManagement/FinancialManagementOrders")
);
const ResturantPaymentOrders = React.lazy(() =>
  import("./pages/FinancialManagement/ResturantPaymentOrders")
);
const DriversPaymentOrders = React.lazy(() =>
  import("./pages/FinancialManagement/DriversPaymentOrders")
);
const ViewFinancialManagementOrders = React.lazy(() =>
  import("./pages/FinancialManagement/ViewFinancialManagementOrders")
);
const PaymentsOrdersBills = React.lazy(() =>
  import("./pages/FinancialManagement/PaymentsOrdersBills")
);

const FAQ = React.lazy(() => import("./pages/FAQ/FAQ"));
const AddFAQ = React.lazy(() => import("./pages/FAQ/AddFAQ"));

const EditeFAQPage = React.lazy(() => import("./pages/FAQ/EditeFAQ"));

function App() {
  const dispatch = useDispatch();
  const { isAuthLoading } = useSelector((state) => state.AuthSlice);
  const { isAdminLoading, AdminDetailsArr } = useSelector(
    (state) => state.AdminSlice
  );
  const { isProductLoading } = useSelector((state) => state.ProductSlice);
  const { isDriversLoading } = useSelector((state) => state.DriversSlice);
  const { isRestaurantLoading } = useSelector(
    (state) => state.RestaurantsSlice
  );
  const { isHomeLoading } = useSelector((state) => state.HomeSlice);

  useEffect(() => {
    if (!AdminDetailsArr) {
      dispatch(getAdminDetails());
    }
  }, [dispatch, AdminDetailsArr]);

  return (
    <>
      {isAuthLoading && <Loader />}
      {isAdminLoading && <Loader />}
      {isProductLoading && <Loader />}
      {isDriversLoading && <Loader />}
      {isRestaurantLoading && <Loader />}
      {isHomeLoading && <Loader />}
      <Suspense fallback={<Loader />}>
        <Routes>
          <Route element={<Protected />}>
            <Route path="*" element={<Profile />} />
            <Route path="/:lang" element={<Profile />} />
            <Route path="/profile/:lang" element={<Profile />} />
            {AdminDetailsArr?.permissions?.show_settings && (
              <Route path="/settings/:lang" element={<Settings />} />
            )}

            {/* Settings */}
            {AdminDetailsArr?.permissions?.show_admin && (
              <>
                <Route path="/Admin/:lang" element={<Admin />} />
                <Route path="/AddAdmin/:lang" element={<AddAdmin />} />
                <Route path="/AdminEdit/:id/:lang" element={<AdminEdit />} />
              </>
            )}

            {AdminDetailsArr?.permissions?.show_user && (
              <>
                <Route path="/Users/:lang" element={<Users />} />
                <Route path="/Edit_Users/:id/:lang" element={<Edit_Users />} />
                <Route path="/AddUsers/:lang" element={<AddUsers />} />
              </>
            )}
            {AdminDetailsArr?.permissions?.show_restaurants && (
              <>
                <Route path="/Resturants/:lang" element={<Resturants />} />
                <Route
                  path="/AddRestaurants/:lang"
                  element={<AddRestaurantsWrap />}
                />
                <Route
                  path="/RestaurantView/:id/:lang"
                  element={<RestaurantView />}
                />
                <Route
                  path="/RestaurantEdit/:id/:lang"
                  element={<RestaurantEditWrap />}
                />
              </>
            )}
            {AdminDetailsArr?.permissions?.show_plans && (
              <>
                <Route path="/Plans/:lang" element={<Plans />} />
                <Route path="/PlansView/:id/:lang" element={<PlansView />} />
                <Route path="/PlansEdit/:id/:lang" element={<PlansEdit />} />
                <Route path="/PlansAdd/:lang" element={<PlansAdd />} />
              </>
            )}
            {AdminDetailsArr?.permissions?.show_class && (
              <>
                <Route path="/Class/:lang" element={<Class />} />
                <Route path="/AddClass/:lang" element={<AddClass />} />
                <Route path="/EditClass/:id/:lang" element={<EditClass />} />
              </>
            )}
            {AdminDetailsArr?.permissions?.show_categories && (
              <>
                <Route path="/Category/:lang" element={<Category />} />
                <Route path="/AddCategory/:lang" element={<AddCategory />} />
                <Route
                  path="/EditCategory/:id/:lang"
                  element={<EditCategory />}
                />
              </>
            )}
            {AdminDetailsArr?.permissions?.show_zones && (
              <>
                <Route path="/Zones/:lang" element={<Zones />} />
                <Route path="/Addzones/:lang" element={<AddZoneWrap />} />
                <Route
                  path="/edite-zones/:id/:lang"
                  element={<EditeZoneWrap />}
                />
              </>
            )}

            {AdminDetailsArr?.permissions?.show_drivers && (
              <>
                <Route path="/Drivers/:lang" element={<Drivers />} />
                <Route path="/AddDrivers/:lang" element={<AddDriverWrap />} />
                <Route
                  path="/EditDrivers/:id/:lang"
                  element={<EditeDriverWrap />}
                />
                <Route
                  path="/DriversView/:id/:lang"
                  element={<DriversView />}
                />
                {/* DriverContact */}
              </>
            )}

            {AdminDetailsArr?.permissions?.show_sliders && (
              <>
                <Route path="/Slider/:lang" element={<Slider />} />
                <Route path="/AddSlider/:lang" element={<AddSlider />} />
                <Route
                  path="/Edit_Slider/:id/:lang"
                  element={<Edit_Slider />}
                />
              </>
            )}
            {AdminDetailsArr?.permissions?.show_countries && (
              <>
                <Route path="/Country/:lang" element={<Country />} />
                <Route path="/AddCountry/:lang" element={<AddCountry />} />
                <Route
                  path="/Edit_Country/:id/:lang"
                  element={<Edit_Country />}
                />
              </>
            )}
            {AdminDetailsArr?.permissions?.show_cities && (
              <>
                <Route path="/City/:lang" element={<City />} />
                <Route path="/AddCity/:lang" element={<AddCity />} />

                <Route path="/Edit_City/:id/:lang" element={<EditCity />} />
              </>
            )}
            {AdminDetailsArr?.permissions?.show_transactions && (
              <>
                <Route
                  path="/FinancialManagementOrders/:lang"
                  element={<FinancialManagementOrders />}
                />
                <Route
                  path="/ResturantPaymentOrders/:lang"
                  element={<ResturantPaymentOrders />}
                />
                <Route
                  path="/DriversPaymentOrders/:lang"
                  element={<DriversPaymentOrders />}
                />
                <Route
                  path="/ViewFinancialManagementOrders/:id/:lang"
                  element={<ViewFinancialManagementOrders />}
                />
                <Route
                  path="/PaymentsOrdersBills/:lang"
                  element={<PaymentsOrdersBills />}
                />
              </>
            )}

            {AdminDetailsArr?.permissions?.show_orders && (
              <>
                <Route path="/Orders/:lang" element={<Orders />} />
                <Route path="/AddOrder/:lang" element={<AddOrder />} />
                <Route path="/ViewOrder/:id/:lang" element={<ViewOrder />} />
              </>
            )}
            {AdminDetailsArr?.permissions?.show_contact_u_s && (
              <>
                <Route path="/contact/:lang" element={<Contact />} />
                <Route
                  path="/driver-contact/:lang"
                  element={<DriverContact />}
                />
              </>
            )}
            <Route path="/Contracts/:lang" element={<Contracts />} />
            <Route
              path="/FollowContracts/:lang"
              element={<FollowContracts />}
            />
            <Route path="/AddContract/:lang" element={<AddContract />} />
            <Route
              path="/EditeContarct/:id/:lang"
              element={<EditeContarct />}
            />
            {/* EditeContarct */}
            <>
              <Route path="/faqs/:lang" element={<FAQ />} />
              <Route path="/add-faqs/:lang" element={<AddFAQ />} />
              <Route path="/edite-faqs/:id/:lang" element={<EditeFAQPage />} />
            </>
          </Route>

          <Route path="/login/:lang" element={<Login />} />
        </Routes>
      </Suspense>
    </>
  );
}

export default App;
