import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import Cookies from "js-cookie";

export const getNoftfications = createAsyncThunk(
  "Home/getNoftfications",
  async (_, thunkAPI) => {
    const { rejectWithValue } = thunkAPI;
    try {
      const data = await axios
        .get(`${process.env.REACT_APP_BACKEND_API}api/admin/notifications`, {
          headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
            Authorization: `Bearer ${Cookies.get("Willgo_Super_Token")}`,
          },
          params: {
            only_not_read: 1,
          },
        })
        .then((res) => res.data);
      return data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);
// updateNotifications
export const updateNotifications = createAsyncThunk(
  "Home/updateNotifications",
  async (id, thunkAPI) => {
    const { rejectWithValue } = thunkAPI;
    try {
      const data = await axios
        .post(
          `${process.env.REACT_APP_BACKEND_API}api/admin/notifications/${id}/mark-as-read`,
          {},
          {
            headers: {
              "Content-Type": "application/json",
              Accept: "application/json",
              Authorization: `Bearer ${Cookies.get("Willgo_Super_Token")}`,
            },
          }
        )
        .then((res) => res.data);
      return data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

export const getContact = createAsyncThunk(
  "Home/getContact",
  async (_, thunkAPI) => {
    const { rejectWithValue } = thunkAPI;
    try {
      const data = await axios
        .get(`${process.env.REACT_APP_BACKEND_API}api/admin/contactus`, {
          headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
            Authorization: `Bearer ${Cookies.get("Willgo_Super_Token")}`,
          },
        })
        .then((res) => res.data);
      return data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

export const sendAsnwer = createAsyncThunk(
  "Home/sendAsnwer",
  async (data, thunkAPI) => {
    const { rejectWithValue } = thunkAPI;
    try {
      const result = await axios
        .post(
          `${process.env.REACT_APP_BACKEND_API}api/admin/contactus/${data.id}/update`,
          data,
          {
            headers: {
              "Content-Type": "application/json",
              Accept: "application/json",
              Authorization: `Bearer ${Cookies.get("Willgo_Super_Token")}`,
            },
          }
        )
        .then((res) => res.data);
      return result;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);
export const DeleteAsnwer = createAsyncThunk(
  "Product/DeleteAsnwer",
  async (id, thunkAPI) => {
    const { rejectWithValue } = thunkAPI;
    try {
      const response = await axios.delete(
        `${process.env.REACT_APP_BACKEND_API}api/admin/contactus/${id}`,
        {
          headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
            Authorization: `Bearer ${Cookies.get("Willgo_Super_Token")}`,
          },
        }
      );
      return response.data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

export const getDriverContact = createAsyncThunk(
  "Home/getDriverContact",
  async (_, thunkAPI) => {
    const { rejectWithValue } = thunkAPI;
    try {
      const data = await axios
        .get(`${process.env.REACT_APP_BACKEND_API}api/admin/driver_contactus`, {
          headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
            Authorization: `Bearer ${Cookies.get("Willgo_Super_Token")}`,
          },
        })
        .then((res) => res.data);
      return data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

export const sendDriverAsnwer = createAsyncThunk(
  "Home/sendDriverAsnwer",
  async (data, thunkAPI) => {
    const { rejectWithValue } = thunkAPI;
    try {
      const result = await axios
        .post(
          `${process.env.REACT_APP_BACKEND_API}api/admin/driver_contactus/${data.id}/update`,
          data,
          {
            headers: {
              "Content-Type": "application/json",
              Accept: "application/json",
              Authorization: `Bearer ${Cookies.get("Willgo_Super_Token")}`,
            },
          }
        )
        .then((res) => res.data);
      return result;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);
export const DeleteDriverAsnwer = createAsyncThunk(
  "Product/DeleteDriverAsnwer",
  async (id, thunkAPI) => {
    const { rejectWithValue } = thunkAPI;
    try {
      const response = await axios.delete(
        `${process.env.REACT_APP_BACKEND_API}api/admin/driver_contactus/${id}`,
        {
          headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
            Authorization: `Bearer ${Cookies.get("Willgo_Super_Token")}`,
          },
        }
      );
      return response.data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

export const getFAQByIDRequest = createAsyncThunk(
  "Home/getFAQByIDRequest",
  async (id, thunkAPI) => {
    const { rejectWithValue } = thunkAPI;
    try {
      const data = await axios
        .get(`${process.env.REACT_APP_BACKEND_API}api/admin/faqs/${id}`, {
          headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
            Authorization: `Bearer ${Cookies.get("Willgo_Super_Token")}`,
          },
        })
        .then((res) => res.data);
      return data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

export const getFAQ = createAsyncThunk("Home/getFAQ", async (_, thunkAPI) => {
  const { rejectWithValue } = thunkAPI;
  try {
    const data = await axios
      .get(`${process.env.REACT_APP_BACKEND_API}api/admin/faqs`, {
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
          Authorization: `Bearer ${Cookies.get("Willgo_Super_Token")}`,
        },
      })
      .then((res) => res.data);
    return data;
  } catch (err) {
    return rejectWithValue(err.response.data);
  }
});

export const addFAQ = createAsyncThunk(
  "Product/addFAQ",
  async (data, thunkAPI) => {
    const { rejectWithValue } = thunkAPI;
    try {
      const result = await axios
        .post(`${process.env.REACT_APP_BACKEND_API}api/admin/faqs`, data, {
          headers: {
            "Content-Type": "multipart/form-data",
            Accept: "application/json",
            Authorization: `Bearer ${Cookies.get("Willgo_Super_Token")}`,
          },
        })
        .then((res) => res.data);

      return result;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

export const EditFAQ = createAsyncThunk(
  "Product/EditFAQ",
  async (data, thunkAPI) => {
    const { rejectWithValue } = thunkAPI;
    try {
      const result = await axios
        .post(
          `${process.env.REACT_APP_BACKEND_API}api/admin/faqs/${data.id}/update`,
          data,
          {
            headers: {
              "Content-Type": "multipart/form-data",
              Accept: "application/json",
              Authorization: `Bearer ${Cookies.get("Willgo_Super_Token")}`,
            },
          }
        )
        .then((res) => res.data);

      return result;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

export const deleteFAQ = createAsyncThunk(
  "Product/deleteFAQ",
  async (id, thunkAPI) => {
    const { rejectWithValue } = thunkAPI;
    try {
      const response = await axios.delete(
        `${process.env.REACT_APP_BACKEND_API}api/admin/faqs/${id}`,
        {
          headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
            Authorization: `Bearer ${Cookies.get("Willgo_Super_Token")}`,
          },
        }
      );
      return response.data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

export const getSetting = createAsyncThunk(
  "Home/getSetting",
  async (_, thunkAPI) => {
    const { rejectWithValue } = thunkAPI;
    try {
      const data = await axios
        .get(`${process.env.REACT_APP_BACKEND_API}api/admin/settings`, {
          headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
            Authorization: `Bearer ${Cookies.get("Willgo_Super_Token")}`,
          },
        })
        .then((res) => res.data);
      return data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);
export const addSetting = createAsyncThunk(
  "Home/addSetting",
  async (data, thunkAPI) => {
    const { rejectWithValue } = thunkAPI;
    try {
      const result = await axios
        .post(`${process.env.REACT_APP_BACKEND_API}api/admin/settings`, data, {
          headers: {
            "Content-Type": "multipart/form-data",
            Accept: "application/json",
            Authorization: `Bearer ${Cookies.get("Willgo_Super_Token")}`,
          },
        })
        .then((res) => res.data);
      return result;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

export const updateSetting = createAsyncThunk(
  "Home/updateSetting",
  async (data, thunkAPI) => {
    const { rejectWithValue } = thunkAPI;
    try {
      const result = await axios
        .post(
          `${process.env.REACT_APP_BACKEND_API}api/admin/settings/${data.id}/update`,
          data,
          {
            headers: {
              "Content-Type": "multipart/form-data",
              Accept: "application/json",
              Authorization: `Bearer ${Cookies.get("Willgo_Super_Token")}`,
            },
          }
        )
        .then((res) => res.data);
      return result;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);
const HomeSlice = createSlice({
  name: "Home",
  initialState: {
    isHomeLoading: false,
    error: null,
    NotificationsArr: null,
    ContactArray: null,
    DriverContactArray: null,
    FAQArray: null,
    FAQByIDArray: null,

    SettingsArray: null,
  },

  extraReducers: {
    [getNoftfications.pending]: (state, action) => {
      state.isHomeLoading = true;
    },
    [getNoftfications.fulfilled]: (state, action) => {
      state.NotificationsArr = action.payload.data;
      state.isHomeLoading = false;
    },
    [getNoftfications.rejected]: (state, action) => {
      state.isHomeLoading = false;
    },
    // updateNotifications
    [updateNotifications.pending]: (state, action) => {
      state.isHomeLoading = true;
    },
    [updateNotifications.fulfilled]: (state, action) => {
      state.isHomeLoading = false;
    },
    [updateNotifications.rejected]: (state, action) => {
      state.isHomeLoading = false;
    },
    [getContact.pending]: (state, action) => {
      state.isHomeLoading = true;
    },
    [getContact.fulfilled]: (state, action) => {
      state.ContactArray = action.payload.data;
      state.isHomeLoading = false;
    },
    [getContact.rejected]: (state, action) => {
      state.isHomeLoading = false;
    },
    // sendAsnwer
    [sendAsnwer.pending]: (state, action) => {
      state.isHomeLoading = true;
    },
    [sendAsnwer.fulfilled]: (state, action) => {
      state.isHomeLoading = false;
    },
    [sendAsnwer.rejected]: (state, action) => {
      state.isHomeLoading = false;
    },
    // DeleteAsnwer
    [DeleteAsnwer.pending]: (state, action) => {
      state.isHomeLoading = true;
    },
    [DeleteAsnwer.fulfilled]: (state, action) => {
      state.isHomeLoading = false;
      state.ContactArray = state.ContactArray.filter(
        (ele) => ele.id !== action.meta.arg
      );
    },
    [DeleteAsnwer.rejected]: (state, action) => {
      state.isHomeLoading = false;
    },

    [getDriverContact.pending]: (state, action) => {
      state.isHomeLoading = true;
    },
    [getDriverContact.fulfilled]: (state, action) => {
      state.DriverContactArray = action.payload.data;
      state.isHomeLoading = false;
    },
    [getDriverContact.rejected]: (state, action) => {
      state.isHomeLoading = false;
    },
    // sendDriverAsnwer
    [sendDriverAsnwer.pending]: (state, action) => {
      state.isHomeLoading = true;
    },
    [sendDriverAsnwer.fulfilled]: (state, action) => {
      state.isHomeLoading = false;
    },
    [sendDriverAsnwer.rejected]: (state, action) => {
      state.isHomeLoading = false;
    },
    // DeleteDriverAsnwer
    [DeleteDriverAsnwer.pending]: (state, action) => {
      state.isHomeLoading = true;
    },
    [DeleteDriverAsnwer.fulfilled]: (state, action) => {
      state.isHomeLoading = false;
      state.DriverContactArray = state.DriverContactArray.filter(
        (ele) => ele.id !== action.meta.arg
      );
    },
    [DeleteDriverAsnwer.rejected]: (state, action) => {
      state.isHomeLoading = false;
    },

    [getFAQByIDRequest.pending]: (state, action) => {
      state.isHomeLoading = true;
    },
    [getFAQByIDRequest.fulfilled]: (state, action) => {
      state.FAQByIDArray = action.payload.data;
      state.isHomeLoading = false;
    },
    [getFAQByIDRequest.rejected]: (state, action) => {
      state.isHomeLoading = false;
    },

    // getFAQ
    [getFAQ.pending]: (state, action) => {
      state.isHomeLoading = true;
    },
    [getFAQ.fulfilled]: (state, action) => {
      state.FAQArray = action.payload.data;
      state.isHomeLoading = false;
    },
    [getFAQ.rejected]: (state, action) => {
      state.isHomeLoading = false;
    },

    [addFAQ.pending]: (state, action) => {
      state.isHomeLoading = true;
    },
    [addFAQ.fulfilled]: (state, action) => {
      state.isHomeLoading = false;
    },
    [addFAQ.rejected]: (state, action) => {
      state.isHomeLoading = false;
    },
    [EditFAQ.pending]: (state, action) => {
      state.isHomeLoading = true;
    },
    [EditFAQ.fulfilled]: (state, action) => {
      state.isHomeLoading = false;
    },
    [EditFAQ.rejected]: (state, action) => {
      state.isHomeLoading = false;
    },
    [deleteFAQ.pending]: (state, action) => {
      state.isHomeLoading = true;
    },
    [deleteFAQ.fulfilled]: (state, action) => {
      state.FAQArray = state.FAQArray.filter(
        (ele) => ele.id !== action.meta.arg
      );
      state.isHomeLoading = false;
    },
    [deleteFAQ.rejected]: (state, action) => {
      state.isHomeLoading = false;
    },

    [getSetting.pending]: (state, action) => {
      state.isHomeLoading = true;
    },
    [getSetting.fulfilled]: (state, action) => {
      state.SettingsArray = action.payload.data;
      state.isHomeLoading = false;
    },
    [getSetting.rejected]: (state, action) => {
      state.isHomeLoading = false;
    },
    [addSetting.pending]: (state, action) => {
      state.isHomeLoading = true;
    },
    [addSetting.fulfilled]: (state, action) => {
      state.isHomeLoading = false;
    },
    [addSetting.rejected]: (state, action) => {
      state.isHomeLoading = false;
    },
    [updateSetting.pending]: (state, action) => {
      state.isHomeLoading = true;
    },
    [updateSetting.fulfilled]: (state, action) => {
      state.isHomeLoading = false;
    },
    [updateSetting.rejected]: (state, action) => {
      state.isHomeLoading = false;
    },
  },
});

export default HomeSlice.reducer;
