import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import Cookies from "js-cookie";

export const getRestaurantNoContract = createAsyncThunk(
  "Contract/getRestaurantNoContract",
  async (_, thunkAPI) => {
    const { rejectWithValue } = thunkAPI;
    try {
      const result = await axios
        .get(`${process.env.REACT_APP_BACKEND_API}api/admin/restaurants`, {
          headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
            Authorization: `Bearer ${Cookies.get("Willgo_Super_Token")}`,
          },
          params: {
            DontHaveContract: 1,
          },
        })
        .then((res) => res.data);
      return result;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);
export const getDriversNoContract = createAsyncThunk(
  "Contract/getDriversNoContract",
  async (_, thunkAPI) => {
    const { rejectWithValue } = thunkAPI;
    try {
      const result = await axios
        .get(`${process.env.REACT_APP_BACKEND_API}api/admin/drivers`, {
          headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
            Authorization: `Bearer ${Cookies.get("Willgo_Super_Token")}`,
          },
          params: {
            DontHaveContract: 1,
          },
        })
        .then((res) => res.data);
      return result;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

export const getContracts = createAsyncThunk(
  "Contract/getContracts",
  async (data, thunkAPI) => {
    const { rejectWithValue } = thunkAPI;
    try {
      const result = await axios
        .get(`${process.env.REACT_APP_BACKEND_API}api/admin/contracts`, {
          headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
            Authorization: `Bearer ${Cookies.get("Willgo_Super_Token")}`,
          },
          params: data && data,
        })
        .then((res) => res.data);
      return result;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

export const getContractsID = createAsyncThunk(
  "Contract/getContractsID",
  async (id, thunkAPI) => {
    const { rejectWithValue } = thunkAPI;
    try {
      const result = await axios
        .get(`${process.env.REACT_APP_BACKEND_API}api/admin/contracts/${id}`, {
          headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
            Authorization: `Bearer ${Cookies.get("Willgo_Super_Token")}`,
          },
        })
        .then((res) => res.data);
      return result;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

export const addContracts = createAsyncThunk(
  "Contract/addContracts",
  async (data, thunkAPI) => {
    const { rejectWithValue } = thunkAPI;
    try {
      const result = await axios
        .post(`${process.env.REACT_APP_BACKEND_API}api/admin/contracts`, data, {
          headers: {
            "Content-Type": "multipart/form-data",
            Accept: "application/json",
            Authorization: `Bearer ${Cookies.get("Willgo_Super_Token")}`,
          },
        })
        .then((res) => res.data);
      return result;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);
export const updateContracts = createAsyncThunk(
  "Contract/updateContracts",
  async (data, thunkAPI) => {
    const { rejectWithValue } = thunkAPI;
    try {
      const result = await axios
        .post(
          `${process.env.REACT_APP_BACKEND_API}api/admin/contracts/${data.id}/update`,
          data,
          {
            headers: {
              "Content-Type": "multipart/form-data",
              Accept: "application/json",
              Authorization: `Bearer ${Cookies.get("Willgo_Super_Token")}`,
            },
          }
        )
        .then((res) => res.data);
      return result;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

export const getFollowUpContracts = createAsyncThunk(
  "Contract/getFollowUpContracts",
  async (data, thunkAPI) => {
    const { rejectWithValue } = thunkAPI;
    try {
      const result = await axios
        .get(
          `${process.env.REACT_APP_BACKEND_API}api/admin/contracts_follow_ups`,
          {
            headers: {
              "Content-Type": "application/json",
              Accept: "application/json",
              Authorization: `Bearer ${Cookies.get("Willgo_Super_Token")}`,
            },
            params: data && data,
          }
        )
        .then((res) => res.data);
      return result;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

export const updateFollowUpContracts = createAsyncThunk(
  "Contract/updateFollowUpContracts",
  async (data, thunkAPI) => {
    const { rejectWithValue } = thunkAPI;
    try {
      const result = await axios
        .post(
          `${process.env.REACT_APP_BACKEND_API}api/admin/contracts_follow_ups/${data.id}/update`,
          data,
          {
            headers: {
              "Content-Type": "multipart/form-data",
              Accept: "application/json",
              Authorization: `Bearer ${Cookies.get("Willgo_Super_Token")}`,
            },
          }
        )
        .then((res) => res.data);
      return result;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

const ConrtactSlice = createSlice({
  name: "Contract",
  initialState: {
    isContractLoading: false,
    error: null,
    ContractArr: null,
    DriversNoContract: null,
    RestaurantNoContractArray: null,
    Contract_IDArr: null,
    Contracts_FollowUps_Array: null,
  },

  extraReducers: (builder) => {
    builder
      .addCase(getRestaurantNoContract.pending, (state) => {
        state.isContractLoading = true;
      })
      .addCase(getRestaurantNoContract.fulfilled, (state, action) => {
        state.isContractLoading = false;
        state.RestaurantNoContractArray = action.payload.data;
      })
      .addCase(getRestaurantNoContract.rejected, (state, action) => {
        state.isContractLoading = false;
      })

      .addCase(getDriversNoContract.pending, (state) => {
        state.isContractLoading = true;
      })
      .addCase(getDriversNoContract.fulfilled, (state, action) => {
        state.isContractLoading = false;
        state.DriversNoContract = action.payload.data;
      })
      .addCase(getDriversNoContract.rejected, (state, action) => {
        state.isContractLoading = false;
      })

      .addCase(getContracts.pending, (state) => {
        state.isContractLoading = true;
      })
      .addCase(getContracts.fulfilled, (state, action) => {
        state.isContractLoading = false;
        state.ContractArr = action.payload.data;
      })
      .addCase(getContracts.rejected, (state, action) => {
        state.isContractLoading = false;
      })
      // getContractsID
      .addCase(getContractsID.pending, (state) => {
        state.isContractLoading = true;
      })
      .addCase(getContractsID.fulfilled, (state, action) => {
        state.isContractLoading = false;
        state.Contract_IDArr = action.payload.data;
      })
      .addCase(getContractsID.rejected, (state, action) => {
        state.isContractLoading = false;
      })
      // addContracts
      .addCase(addContracts.pending, (state) => {
        state.isContractLoading = true;
      })
      .addCase(addContracts.fulfilled, (state, action) => {
        state.isContractLoading = false;
      })
      .addCase(addContracts.rejected, (state, action) => {
        state.isContractLoading = false;
      })
      // updateContracts
      .addCase(updateContracts.pending, (state) => {
        state.isContractLoading = true;
      })
      .addCase(updateContracts.fulfilled, (state, action) => {
        state.isContractLoading = false;
      })
      .addCase(updateContracts.rejected, (state, action) => {
        state.isContractLoading = false;
      })

      // getFollowUpContracts
      .addCase(getFollowUpContracts.pending, (state) => {
        state.isContractLoading = true;
      })
      .addCase(getFollowUpContracts.fulfilled, (state, action) => {
        state.isContractLoading = false;
        state.Contracts_FollowUps_Array = action.payload.data;
      })
      .addCase(getFollowUpContracts.rejected, (state, action) => {
        state.isContractLoading = false;
      })
      // updateFollowUpContracts
      .addCase(updateFollowUpContracts.pending, (state) => {
        state.isContractLoading = true;
      })
      .addCase(updateFollowUpContracts.fulfilled, (state, action) => {
        state.isContractLoading = false;
      })
      .addCase(updateFollowUpContracts.rejected, (state, action) => {
        state.isContractLoading = false;
      });
  },
});

export default ConrtactSlice.reducer;
