import React, { useEffect, useRef } from "react";
import { Toast } from "primereact/toast";
import { InputText } from "primereact/inputtext";
import { useFormik } from "formik";
import { classNames } from "primereact/utils";
import style from "../../styles/Forms.module.css";
import { MdOutlineDashboardCustomize } from "react-icons/md";
import { NavLink, useNavigate, useParams } from "react-router-dom";
import { Message } from "primereact/message";
import { MultiSelect } from "primereact/multiselect";
import { useDispatch, useSelector } from "react-redux";
import { Dropdown } from "primereact/dropdown";
import { IoIosInformationCircleOutline, IoMdCloudUpload } from "react-icons/io";
import { useTranslation } from "react-i18next";
import { InputNumber } from "primereact/inputnumber";

import {
  getContracts,
  getContractsID,
  getDriversNoContract,
  getRestaurantNoContract,
  updateContracts,
} from "../../store/ConrtactSlice";

const EditeContarct = () => {
  const toast = useRef(null);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { t } = useTranslation();
  const { id, lang } = useParams();
  const {
    DriversNoContract,
    RestaurantNoContractArray,
    Contract_IDArr,
  } = useSelector((state) => state.ConrtactSlice);

  useEffect(() => {
    dispatch(getContractsID(id));
  }, [dispatch, id]);
  useEffect(() => {
    if (!DriversNoContract) {
      dispatch(getDriversNoContract());
    }
  }, [DriversNoContract, dispatch]);
  useEffect(() => {
    if (!RestaurantNoContractArray) {
      dispatch(getRestaurantNoContract());
    }
  }, [RestaurantNoContractArray, dispatch]);

  const showSuccess = () => {
    toast.current.show({
      severity: "success",
      summary: `${t("Saved")}`,
    });
  };

  const showError = (message) => {
    toast.current.show({
      severity: "error",
      summary: message,
      life: 3000,
    });
  };

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      id: id,
      contract_name_ar: Contract_IDArr?.contract_name_ar || "",
      contract_name_en: Contract_IDArr?.contract_name_en || "",
      contract_type: Contract_IDArr?.contract_type || null,
      contract_period: Contract_IDArr?.contract_period || "",
      auto_renew: Contract_IDArr?.auto_renew || null,
      percentage: Contract_IDArr?.percentage || "",
      vendors_ids:
        Contract_IDArr?.vendors_ids?.map((ele) => parseInt(ele)) || null,
      file: null,
    },
    validate: (data) => {
      const errors = {};
      if (!data.contract_name_ar.trim()) {
        errors.contract_name_ar = t(
          "Contracts.Contract name already exists, please choose a unique name"
        );
      }
      if (!data.contract_name_en.trim()) {
        errors.contract_name_en = t(
          "Contracts.This name already exists in the selected class"
        );
      }
      if (!data.contract_type) {
        errors.contract_type = t("Contracts.Contract Type is required.");
      }
      if (!data.contract_period.trim()) {
        errors.contract_period = t("Contracts.Contract Period is required.");
      }
      if (!data.auto_renew) {
        errors.auto_renew = t("Contracts.Auto Renew option is required.");
      }
      // if (data.contract_type === "Restaurant" && !data.percentage) {
      //   errors.percentage = t("Contracts.Percentage is required.");
      // }
      if (!data.vendors_ids) {
        errors.vendors_ids = t("Contracts.Vendors selection is required.");
      }
      // if (!data.file) {
      //   errors.file = t("Contracts.Contract file is required.");
      // }
      console.log(errors);
      return errors;
    },
    onSubmit: (values) => {
      const Data = {
        ...values,
        percentage: values.percentage || null,
      };
      if (values) {
        dispatch(updateContracts(Data))
          .unwrap()
          .then(() => {
            dispatch(getContracts());
            showSuccess();
            formik.resetForm();
            navigate(`/Contracts/${lang}`);
          })
          .catch((err) => {
            err?.errors?.forEach((error) => showError(error));
          });
      }
    },
  });

  const isFormFieldInvalid = (name) =>
    !!(formik.touched[name] && formik.errors[name]);
  const getFormErrorMessage = (name) =>
    isFormFieldInvalid(name) ? (
      <small className="p-error">{formik.errors[name]}</small>
    ) : (
      <small className="p-error">&nbsp;</small>
    );

  const TypeOptions = [
    { name: t("Contracts.Driver"), id: "Driver" },
    { name: t("Contracts.Restaurant"), id: "Restaurant" },
  ];

  return (
    <div className="container-fluid">
      <Toast ref={toast} />
      <div className="bredcreamp">
        <NavLink to={`/${lang}`}>
          <MdOutlineDashboardCustomize /> {t("Contracts.Dashboard")}
        </NavLink>
        <NavLink to={`/Contracts/${lang}`}>
          {" "}
          / {t("Contracts.Contracts")}
        </NavLink>{" "}
        / {t("Contracts.Create New Contract")}
      </div>
      <div className="cardd">
        <div className="title-page mb-3 flex justify-content-between align-items-center">
          <h3>{t("Contracts.Create New Contract")}</h3>
        </div>
        <div className="card">
          <form
            autoComplete="off"
            onSubmit={formik.handleSubmit}
            className={style.form}
          >
            <div className={style.field}>
              <label htmlFor="contract_name_ar">
                {t("Contracts.Contract Name (English)")}
              </label>
              <InputText
                id="contract_name_ar"
                name="contract_name_ar"
                maxLength={255}
                value={formik.values.contract_name_ar}
                onChange={formik.handleChange}
                className={classNames({
                  "p-invalid": isFormFieldInvalid("contract_name_ar"),
                })}
              />
              {getFormErrorMessage("contract_name_ar")}
            </div>
            <div className={style.field}>
              <label htmlFor="contract_name_en">
                {t("Contracts.Contract Name (English)")}
              </label>
              <InputText
                id="contract_name_en"
                name="contract_name_en"
                maxLength={255}
                value={formik.values.contract_name_en}
                onChange={formik.handleChange}
                className={classNames({
                  "p-invalid": isFormFieldInvalid("contract_name_en"),
                })}
              />
              {getFormErrorMessage("contract_name_en")}
            </div>
            <div className={style.field}>
              <label htmlFor="contract_type">
                {t("Contracts.Contract Type")}
              </label>
              <Dropdown
                disabled
                id="contract_type"
                name="contract_type"
                value={formik.values.contract_type}
                options={TypeOptions}
                onChange={(e) => {
                  formik.setFieldValue("contract_type", e.value);
                  formik.setFieldValue("vendors_ids", null);
                  formik.setFieldValue("percentage", "");
                  // percentage
                }}
                className={classNames({
                  "p-invalid": isFormFieldInvalid("contract_type"),
                })}
                optionValue="id"
                optionLabel="name"
              />
              {getFormErrorMessage("contract_type")}
            </div>
            <div className={style.field}>
              <label htmlFor="contract_period">
                {t("Contracts.Contract Period")}
              </label>
              <Dropdown
                id="contract_period"
                name="contract_period"
                value={formik.values.contract_period}
                options={[
                  { name: t("Contracts.Daily"), id: "daily" },
                  { name: t("Contracts.Weekly"), id: "weekly" },
                  { name: t("Contracts.Monthly"), id: "monthly" },
                  { name: t("Contracts.Quarterly"), id: "quarterly" },
                  { name: t("Contracts.Biannual"), id: "biannual" },
                  { name: t("Contracts.Yearly"), id: "yearly" },
                  { name: t("Contracts.Biennial"), id: "biennial" },
                  { name: t("Contracts.Triennial"), id: "triennial" },
                ]}
                onChange={(e) =>
                  formik.setFieldValue("contract_period", e.value)
                }
                className={classNames({
                  "p-invalid": isFormFieldInvalid("contract_period"),
                })}
                optionValue="id"
                optionLabel="name"
              />
              {getFormErrorMessage("contract_period")}
            </div>
            <div className={style.field}>
              <label htmlFor="auto_renew">{t("Contracts.Auto Renew")}</label>
              <Dropdown
                id="auto_renew"
                name="auto_renew"
                value={formik.values.auto_renew}
                options={[
                  { name: t("Contact.Yes"), id: 1 },
                  { name: t("Contact.No"), id: 0 },
                ]}
                onChange={(e) => formik.setFieldValue("auto_renew", e.value)}
                className={classNames({
                  "p-invalid": isFormFieldInvalid("auto_renew"),
                })}
                optionValue="id"
                optionLabel="name"
              />
              {getFormErrorMessage("auto_renew")}
            </div>
            {formik.values.contract_type === "Restaurant" && (
              <div className={style.field}>
                <label htmlFor="percentage">{t("Contracts.Percentage")}</label>
                <InputNumber
                  id="percentage"
                  name="percentage"
                  value={formik.values.percentage}
                  onValueChange={formik.handleChange}
                  className={classNames({
                    "p-invalid": isFormFieldInvalid("percentage"),
                  })}
                  maxLength={2}
                />
                {getFormErrorMessage("percentage")}
              </div>
            )}
            {formik.values.contract_type === "Driver" && (
              <div className={style.field}>
                <label htmlFor="vendors_ids">
                  {t("Contracts.Assign Vendors")}
                </label>
                {DriversNoContract && (
                  <MultiSelect
                    id="vendors_ids"
                    name="vendors_ids"
                    value={formik.values.vendors_ids}
                    options={DriversNoContract}
                    onChange={(e) =>
                      formik.setFieldValue("vendors_ids", e.value)
                    }
                    className={classNames({
                      "p-invalid": isFormFieldInvalid("vendors_ids"),
                    })}
                    optionValue="id"
                    optionLabel="name"
                  />
                )}
                {getFormErrorMessage("vendors_ids")}
              </div>
            )}
            {formik.values.contract_type === "Restaurant" && (
              <div className={style.field}>
                <label htmlFor="vendors_ids">
                  {t("Contracts.Assign Vendors")}
                </label>
                {RestaurantNoContractArray && (
                  <MultiSelect
                    id="vendors_ids"
                    name="vendors_ids"
                    value={formik.values.vendors_ids}
                    options={RestaurantNoContractArray}
                    onChange={(e) =>
                      formik.setFieldValue("vendors_ids", e.value)
                    }
                    className={classNames({
                      "p-invalid": isFormFieldInvalid("vendors_ids"),
                    })}
                    optionValue="id"
                    optionLabel={lang === "ar" ? "ar_name" : "en_name"}
                  />
                )}
                {getFormErrorMessage("vendors_ids")}
              </div>
            )}
            <div className={style.field}>
              <div className="flex align-items-center justify-content-between">
                <label htmlFor="file">
                  {t("Contracts.Upload Contract File")}
                </label>
                <small className="p-info2">
                  <IoIosInformationCircleOutline />{" "}
                  <span style={{ color: "#A3A3A3" }}>
                    {t(
                      "Contracts.Image dimensions must be  under 1000x1000 pixels, and file size should be below 2 MB."
                    )}
                  </span>
                </small>
              </div>
              <div className={`${style.uploadImg_u} ${style.uploadImg}`}>
                <span> {t("Contracts.Upload Contract File")} </span>
                <IoMdCloudUpload />
                <input
                  type="file"
                  id="file"
                  name="file"
                  accept="application/pdf"
                  onChange={(e) => {
                    const file = e.target.files[0];
                    formik.setFieldValue("file", file);
                  }}
                />
              </div>
              {formik.values.file && (
                <Message
                  severity="success"
                  text={t("Saved")}
                  className="mt-2"
                />
              )}
              {getFormErrorMessage("file")}
            </div>
            <button type="submit" className="add-btn ml-auto mt-4">
              {t("Contracts.Add")}
            </button>
          </form>
        </div>
      </div>
    </div>
  );
};

export default EditeContarct;
